import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';

import { getAbility, getViewConfig } from './actions';
import { makeSelectAuthorization, makeSelectViewConfig } from './selectors';

interface AbilityProps {
  children;
  auth: {
    permissions: {
      resource_name: string;
      actions: any;
      restricted_fields: string[];
      restricted_actions: string[];
    }[];
  };
  renderNoAccess;
  access;
  isComponent;
  noAccessAction;
  actions?: string[];
}
/**
 * `Ability` component manages user access control based on the user's permissions.
 * It conditionally renders its children components based on whether the user has
 * the required permissions for a given resource and actions (e.g., 'view', 'edit').
 *
 * The component checks the `auth` prop for the user's permissions and determines
 * if the required actions (defaulting to 'view') are available for a specific resource
 * identified by the `access` prop. If the user does not have the required permissions,
 * a fallback component or UI can be rendered using the `renderNoAccess` prop.
 *
 * @component
 *
 * @param {Object} props - The props for the Ability component.
 * @param {React.ReactNode} props.children - The content or components to be conditionally rendered.
 * @param {Object} props.auth - The user authorization data, including permissions.
 * @param {Array<Object>} props.auth.permissions - Array of permissions, each containing a `resource_name` and allowed `actions`.
 * @param {string} props.access - The resource name (e.g., 'dashboard', 'settings') to check permissions for.
 * @param {boolean} [props.isComponent=false] - Determines if the Ability component wraps other components.
 * @param {Function} [props.renderNoAccess=null] - A function returning fallback UI if the user lacks permissions.
 * @param {string[]} [props.actions=['view']] - List of actions the user needs for the given resource (e.g., ['view', 'edit']). If no actions are provided, `'view'` will be added by default. For example, if `['edit']` is passed, the effective actions will be `['view', 'edit']`.
 * @param {string} [props.noAccessAction] - **Deprecated**. Previously used for handling no access, but now unused and should be removed.
 * @returns {React.ReactNode} - The rendered children if the user has access, or a fallback UI if they don't.
 *
 * @example
 * // Use case 1: Check if the user has access to the "rewards" resource. If the user does not have permission,
 * // the `Forbidden` component will be rendered. If the user has access, the `Reward` component will be rendered.
 *
 * <Ability renderNoAccess={Forbidden} access="rewards">
 *   <Reward {...props} />
 * </Ability>
 *
 * //In this case, the component checks if the `auth.permissions` contains a resource with the name `"rewards"`.
 * //If such a resource exists, the component renders the `Reward` component. Otherwise, it calls `renderNoAccess`
 * //(in this case, the `Forbidden` component) to show a fallback UI.
 *
 * @example
 * // Use case 2: Check if the user has access to the "campaigns" resource. If the user has the `"view"` action
 * // permission, the `CampaignCreatePage` component will be rendered. Otherwise, a fallback UI (e.g., `ForbiddenPage`)
 * // will be displayed if `renderNoAccess` is provided.
 *
 * <Ability
 *   isComponent
 *   renderNoAccess={() => <ForbiddenPage />}
 *   access="campaigns"
 * >
 *   <CampaignCreatePage {...props} />
 * </Ability>
 *
 * //In this case, the component checks if the user has a permission for the `"campaigns"` resource. It then checks
 * //if the permission includes actions, and whether the `"view"` action is available for the user (since `actions`
 * //is not passed as a prop, the `"view"` action is checked by default). If the user has the `"view"` permission for
 * //the `"campaigns"` resource, the `CampaignCreatePage` component is rendered. If not, the `ForbiddenPage` is rendered.
 *
 */
class Ability extends Component<AbilityProps> {
  static propTypes = {
    children: PropTypes.node,
    isComponent: PropTypes.bool,
    noAccessAction: PropTypes.string,
    access: PropTypes.string,
    renderNoAccess: PropTypes.func,
    actions: PropTypes.array
  };

  render() {
    // noAccessAction is useless and should be cleaned up in subsequent commits
    const { children, auth, renderNoAccess, access, isComponent, actions } = this.props;
    const requiredActions = actions || [];

    if (!requiredActions.includes('view')) {
      requiredActions.push('view');
    }

    if (Object.keys(auth).length !== 0) {
      if ((auth.permissions || []).find(obj => obj.resource_name === access)) {
        if (!isComponent) {
          return children;
        } else {
          let permSec = (auth.permissions || []).find(obj => obj.resource_name === access);

          if ('actions' in permSec) {
            if (permSec.actions.length > 0) {
              let objectStyle = { pointerEvents: 'auto' };
              const actionsCheck = requiredActions.every(action =>
                permSec.actions.includes(action)
              );
              if (actionsCheck) {
                //@ts-ignore
                return <span style={objectStyle}>{children}</span>;
              } else {
                //@ts-ignore
                return renderNoAccess ? renderNoAccess() : null;
              }
              // return <div style={objectStyle}>{children}</div>
            } else {
              if (renderNoAccess) {
                return renderNoAccess();
              } else {
                return null;
              }
            }
          }
        }
      } else {
        if (renderNoAccess) {
          return renderNoAccess();
        } else {
          return null;
        }
      }
    } else {
      return null;
    }
  }
}

const mapStateToProps = createStructuredSelector({
  auth: makeSelectAuthorization,
  viewConfig: makeSelectViewConfig()
});

const mapDispatchToProps = dispatch => ({
  getAbility: () => dispatch(getAbility()),
  getViewConfig: () => dispatch(getViewConfig())
});

export default connect(mapStateToProps, mapDispatchToProps)(Ability);
