import React, { useCallback, useState } from 'react';
import { SelectProps, Spin } from 'antd';

import { useGLAccountIdentifierList } from 'api/glaccount';
import { Select } from 'components/Layout/Form';

interface IdentifierSelectProps extends SelectProps {
  showId?: boolean;
  params?: {
    lookup_type: any[];
  };
}

export const GLAcountSelect = ({ params, showId, ...props }: IdentifierSelectProps) => {
  const [search, setSearch] = useState(null);
  const { data = [], isLoading } = useGLAccountIdentifierList({ search_string: search, ...params });
  const options = data
    .filter(item => item.lookup_type.toLowerCase().includes(search?.toLowerCase() || ''))
    .map(item => ({
      value: item.id,
      label: showId ? `${item.lookup_type} (ID: ${item.id})` : item.lookup_type
    }));

  return (
    <Select
      onBlur={(e: React.FocusEvent<HTMLElement, Element>) => {
        e.preventDefault();
        setSearch(null);
      }}
      onSearch={(value: string) => setSearch(value)}
      options={options}
      loading={isLoading}
      filterOption={false}
      notFoundContent={isLoading ? <Spin size="small" /> : null}
      {...props}
    />
  );
};

export default GLAcountSelect;
