import React from 'react';
import { Input, InputProps } from 'antd';
import { useField } from 'formik';

import FItem, { FItemProps } from './FItem';

type FItemInputProps = InputProps & {
  name: string;
  label?: string;
  formItemProps?: FItemProps;
};

const FItemInput = ({ name, label, formItemProps, ...props }: FItemInputProps) => {
  const [field, meta] = useField(name);

  return (
    <FItem meta={meta} label={label} htmlFor={name} {...formItemProps}>
      <Input id={name} {...field} prefix={<span />} suffix={<span />} {...props} />
    </FItem>
  );
};

export default FItemInput;
